import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import GoldData from '../../components/goldData';
export const _frontmatter = {
  "title": "Tasacion oro",
  "image": "../../images/oro.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`¿Qué es la tasación de oro?`}</h2>
    <p>{`La tasación de oro es calcular su precio y se realiza a partir de los quilates y el peso total en gramos de las piezas a evaluar. Existen diferentes purezas de oro que determinan el precio final. Normalmente existe una marca en las piezas de oro que no indican su pureza.`}</p>
    <h2>{`¿Qué tipos de pureza de oro hay?`}</h2>
    <h5>{`TABLA PUREZAS DEL ORO`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}><span style={{
              "marginRight": "20px"
            }}>{` Porcentaje de oro (%) `}</span></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><span style={{
              "marginRight": "20px"
            }}>{`Quilates`}</span></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><span style={{
              "marginRight": "20px"
            }}>{`Denominación`}</span></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><span style={{
              "marginRight": "20px"
            }}>{`Numeración`}</span></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`99,9`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`24`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Oro puro`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`991 M1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`91,6`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`22`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`916 M1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`75,0`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`18`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Oro de Primera Ley`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`750 M1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`58,5`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`14`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Oro de Segunda Ley`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`585 M1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`37,5`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`375 M1`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`¿Cómo saber que el oro es auténtico?`}</h2>
    <p>{`Una de las técnicas más conocidas es usar un imán que no debe atraer la pieza expuesta, pero esto no asegura que tu pieza sea de oro. Lo siguiente es buscar la marca que indica su pureza. Siempre se puede recurrir a un profesional para que evalúe desde la experiencia la pieza y que usará algún elemento como la piedra de toque, ácido nítrico, densímetro o espectrómetro que determinan la masa y la proporción de oro que contiene.`}</p>
    <h2>{`¿Qué son los quilates de oro?`}</h2>
    <p>{`Los quilates es la proporción en peso que tiene el metal precioso en la aleación total.`}</p>
    <h2>{`¿Por qué hay que tasar el oro?`}</h2>
    <p>{`El oro se tasa habitualmente cuando el propietario de las piezas o joyas que contienen el oro quiere realizar su venta ya que no está interesado en tener las joyas o necesita el capital de las mismas.`}</p>
    <h2>{`¿Cómo tasar el oro?`}</h2>
    <p>{`Lo más habitual es ir directamente a una casa de compraventa de oro o joyería para evaluar tus joyas o piezas de oro si tienes interés en su venta pero existe una forma casera muy sencilla por si tienes curiosidad.
Tasa el oro tú mismo siguiendo los siguientes pasos:`}</p>
    <ul>
      <li parentName="ul">{`Pesar la joya en una balanza que tenga la sensibilidad suficiente (Una balanza de cocina electrónica es ideal).`}</li>
      <li parentName="ul">{`Buscar en el interior de la joya la pureza, se marca con la numeración equivalente mostrada en la tabla anterior.`}</li>
      <li parentName="ul">{`La OCU nos ofrece la siguiente calculadora  calculadora de oro de OCU Inversores para saber el precio máximo del total de oro que contienen las piezas.
Hay que tener en cuenta que cuando vayamos a una casa especializada de compraventa de oro nos ofrecerán un valor inferior al obtenido, normalmente sobre un 20% menos.
Es posible que las joyerías te den un precio superior por tus joyas ya que no necesitan tanto margen para que sean rentables. Recomendamos evaluar ambas opciones ya que va a depender del lugar.`}</li>
    </ul>
    <h2>{`¿Cómo tasar oro online de forma gratuita?`}</h2>
    <p>{`La forma de tasar oro online es mediante calculadoras que dan esta posibilidad introduciendo datos, se explican los pasos en la sección ¿Cómo tasar el oro?
Accede a tasar tu oro en la siguiente herramienta.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.ocu.org/inversiones/calculadoras/gold-calculator"
      }}>{`CALCULADORA OCU`}</a></p>
    <h2>{`¿Dónde puedo vender oro?`}</h2>
    <p>{`Tanto en las casas de compraventa como en las joyerías es la forma más habitual.
Existen también otras opciones como Cash Converters que aceptan piezas de oro.
Seguramente las piezas vendidas acabarán fundidas a no ser que vean que se puede sacar más partido si es una joya que pueda tener mercado ya sea por marca o calidad.`}</p>
    <h2>{`¿El precio del oro varía?`}</h2>
    <p>{`Si, el precio del oro está en cotización diaria, como muchos otros productos de valor, se basa en la escala de 24 quilates y a partir de aquí se puede evaluar para las otras categorías según su porcentaje.`}</p>

    <GoldData mdxType="GoldData" />
    <h2>{`¿Cuántos quilates tiene un lingote?`}</h2>
    <p>{`El clásico y conocido lingote de oro tiene 24 quilates, es decir, la pureza máxima de oro.`}</p>
    <h2>{`¿Cuál es el mejor momento para vender oro al mejor precio?`}</h2>
    <p>{`El oro es un valor de refugio para la economía, por lo tanto, suele subir en periodos de crisis o economía en recesión ya que da seguridad. Es seguramente la más segura de las inversiones en periodos complicados, aunque no siempre se puede conocer el mejor momento.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      